import ApiService from "./ApiService"

export async function apiGetCountries (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Country',
        method: 'get',
        params
    })
}

export async function apiGetFacilityCountries (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility/countries',
        method: 'get',
        params
    })
}

export async function apiGetCompanies (params) {
    return ApiService.fetchData({
        url: `/api/v1/admin/Company?active=${params.active? true: false}`,
        method: 'get',
    })
}

export async function apiGetBasin (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Basin',
        method: 'get',
        params
    })
}

export async function apiGetFields (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Field',
        method: 'get',
        params
    })
}

export async function apiGetFormations (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Formation',
        method: 'get',
        params
    })
}

export async function apiGetLicense (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/License',
        method: 'get',
        params
    })
}

export async function apiGetSites(data) {
    return ApiService.fetchData({
        url: '/api/v1/WellSite/Site/getall/pagination',
        method: 'post',
        data
    })
}

// export async function apiCreateSite(data) {
//     return ApiService.fetchData({
//         url: '/api/v1/WellSite/Site',
//         method: 'post',
//         data
//     })
// }

export async function apiCreateSite(data) {
    return ApiService.fetchData({
        url: '/api/v1/WellSite/SiteCompany',
        method: 'post',
        data
    })
}

export async function apiUpdateSite(data) {
    return ApiService.fetchData({
        url: '/api/v1/WellSite/SiteCompany',
        method: 'put',
        data
    })
}

// export async function apiUpdateSite(data) {
//     return ApiService.fetchData({
//         url: '/api/v1/WellSite/Site',
//         method: 'put',
//         data
//     })
// }

export async function apiGetSiteByName(data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/Site/filter/fuzzy?siteName=${data.siteName}`,
        method: 'get',
        headers: {
            "Accept":"text/plain"
        },
        data
    })
}

export async function apiGetSiteById(data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/Site/${data}`,
        method: 'get',
        data
    })
}

export async function apiGetSiteDetailsById(data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteCompany/site/${data}`,
        method: 'get',
        data
    })
}

export async function apiGetSiteCompanyById(data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/Site/${data}/SiteCompany`,
        method: 'get',
        data
    })
}


export async function apiGetCompanyById(data) {
    return ApiService.fetchData({
        url: `/api/v1/admin/Company/${data.id}`,
        method: 'get',
        data
    })
}

export async function apiGetUserAccessBysite (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/User`,
        method: 'GET',
        data
    })
}

export async function apiGetUserAccessBysiteAssigned (data) {
    return ApiService.fetchData({
        url: data.isInternal !== null?`/api/v1/access/UserResourcePermission/details/user/assigned/site?siteId=${data.id}&isInternal=${data.isInternal}`:`/api/v1/access/UserResourcePermission/details/user/assigned/site?siteId=${data.id}`,
        method: 'GET',
    })
}

export async function apiGetContactGroupAccessBysite (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/ContactGroupResourcePermission/details/group/assigned/site?siteId=${data}`,
        method: 'GET',
        data
    })
}

export async function apiGetContactGroupAccessBysiteAvailable (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/ContactGroup`,
        method: 'GET',
        data
    })
}

export async function apiGetUserAccessSite (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/UserResourcePermission/details/user_site?userId=${data.userId}&siteId=${data.siteId}&companyId=${data.companyId}`,
        method: 'GET',
        data
    })
}

export async function apiGetContactGroupAccessSite (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/ContactGroupResourcePermission/details/groupsite?groupId=${data.groupId}&siteId=${data.siteId}&companyId=${data.companyId}`,
        method: 'GET',
        data
    })
}


export async function apiDeleteUserAccessRoleSite (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/UserResourcePermission/remove/user_site/role?userId=${data.userId}&siteId=${data.siteId}&companyId=${data.companyId}&roleId=${data.roleId}`,
        method: 'Delete',
        data
    })
}

export async function apiDeleteUserAccessPermissionSite(data){
    return ApiService.fetchData({
        url: `/api/v1/access/UserResourcePermission/remove/user_site/permission?userId=${data.userId}&siteId=${data.siteId}&companyId=${data.companyId}&permissionId=${data.permissionId}`,
        method: 'Delete',
        data
    })
}

export async function apiGetSiteNotesBySiteId (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteNote/notes/${data.id}`,
        method: 'GET',
        data
    })
}

export async function  apiGetSiteAttachmentsBySiteId(data){
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteAttachment/attachments?siteId=${data.id}`,
        method: 'GET',
        data
    })
}

export async function  apiDeleteSiteAttachment(data){
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteAttachment/${data.id}`,
        method: 'DELETE',
    })
}

export async function apiAddSiteNotesBySiteId (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteNote`,
        method: 'POST',
        data
    })
}

export async function apiUpdateSiteNotesBySiteId (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteNote`,
        method: 'PUT',
        data
    })
}

export async function apiDeleteSiteNotesById (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteNote/${data.id}`,
        method: 'DELETE',
        data
    })
}

export async function apiGetUser (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/User`,
        method: 'GET',
        data
    })
}

export async function apiGetUserFacilities () {
    return ApiService.fetchData({
        url: `/api/v1/admin/Facility/access/site`,
        method: 'GET',
    })
}

export async function apiApproveSite (siteId) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/Site/ApproveSite/${siteId}`,
        method: 'PUT',
    })
}

export async function apiGetSiteAdminFacilities (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/Permission/permissible/facilities?csvPermission=${data}`,
        method: 'GET',
    })
}


export async function apiGetSiteResource  (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/Site/GetSiteDropDown/true`,
        method: 'POST',
        data
    })
}


// Site Alias
export async function apiPostSiteAlias (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteAlias`,
        method: 'POST',
        data
    })
}

export async function apiPutSiteAlias (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteAlias`,
        method: 'PUT',
        data
    })
}

export async function apiDeleteSitAlias (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteAlias/${data.id}`,
        method: 'DELETE',
        data
    })
}

export async function apiGetSiteAliasBySiteId (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/SiteAlias/site/${data}`,
        method: 'GET',
        data
    })
}

// project

export async function apiPostProjectSite (data) {
    return ApiService.fetchData({
        url: `/api/v1/WellSite/ProjectSite/associate/project?siteId=${data.id}`,
        method: 'post',
        data: data.projectIds
    })
}

//notification

export async function apiGetNotifications(data){
    return ApiService.fetchData({
        url: `/api/v1/notification/usernotification/getsite/${data.id}`,
        method: 'GET',
    }) 
}

export async function apiPostNotifications(data){
    return ApiService.fetchData({
        url: `/api/v1/notification/usernotification`,
        method: 'POST',
        data
    }) 
}

export async function apiPutNotifications(data){
    return ApiService.fetchData({
        url: `/api/v1/notification/usernotification`,
        method: 'PUT',
        data
    }) 
}

export async function apiGetSiteLogs(data) {
    return ApiService.fetchData({
        url: `/api/v1/admin/Logs/site?siteId=${data}`,
        method: 'get',
        data
    })
}