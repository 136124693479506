import ApiService from "./ApiService"


// get list of values

export async function apiGetContainerTypeFacility (data) {
    return ApiService.fetchData({
        url: `/api/v1/admin/ContainerType/byfacility?facilityId=${data}`,
        method: 'get',
    })
}

export async function apiGetSampleTypeByFacility (data) {
    return ApiService.fetchData({
        url: `/api/v1/admin/SampleType/onlyactive`,
        method: 'get',
    })
}

//crud

export async function apiDeleteRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Record/${data.id}`,
        method: 'delete',
        data
    })
}

export async function apiPutRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Record`,
        method: 'put',
        data
    })
}

export async function apiPostRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Record`,
        method: 'post',
        data
    })
}

export async function apiGetRecordById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Record/${data.id}`,
        method: 'get',
    })
}

export async function apiGetCloneRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Record/recordclone`,
        method: 'post',
        data
    })
}

//Attachment

export async function apiDeleteRecordAttachment(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/RecordAttachment/${data.id}`,
        method: 'DELETE',
    })
}

export async function  apiGetRecordAttachmentsById(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/RecordAttachment/attachments?recordId=${data.id}`,
        method: 'GET',
        data
    })
}

//notes

export async function apiGetRecordNotesByRecordId (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/RecordNote/notes/${data.id}`,
        method: 'GET',
        data
    })
}

export async function apiAddRecordNotesByRecordId (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/RecordNote`,
        method: 'POST',
        data
    })
}

export async function apiUpdateRecordNotesByRecordId (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/RecordNote`,
        method: 'PUT',
        data
    })
}

export async function apiDeleteRecordNotesById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/RecordNote/${data.id}`,
        method: 'DELETE',
        data
    })
}

export async function apiGetUser (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/User`,
        method: 'GET',
        data
    })
}

//Assign location to Record

export async function apiGetAssignLocationToRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Records/RecordLocation/containers?recordId=${data.id}`,
        method: 'get',
        data
    })
}

export async function apiAssignLocationToRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Records/RecordLocation`,
        method: 'post',
        data
    })
}

export async function apiDeleteAssignLocationToRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Records/RecordLocation/${data.id}`,
        method: 'delete',
        data
    })
}

export async function apiPutAssignLocationToRecord (data) {
    return ApiService.fetchData({
        url: `/api/v1/Records/RecordLocation`,
        method: 'put',
        data
    })
}

//Record Bench

export async function apiGetGeneralInfo (id) {
    return ApiService.fetchData({
        url: `/api/v1/Record/GeneralInfo/${id}`,
        method: 'GET'
    })
}

export async function apiGetContainer (id) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/record?recordId=${id}`,
        method: 'GET'
    })
}

export async function apiGetSample(id) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample/${id}`,
        method: 'GET'
    })
}

export async function apiUpdateGeneralInfo(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/GeneralInfo`,
        method: 'POST',
        data
    })
}

export async function apiUpdateContainer(data, recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/create/record?recordId=${recordId}`,
        method: 'POST',
        data
    })
}

export async function apiUpdateSample(data, recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample?recordId=${recordId}`,
        method: 'POST',
        data
    })
}

export async function apiDeleteGeneralInfo(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/GeneralInfo`,
        method: 'DELETE',
        data
    })
}

export async function apiDeleteSample(data, immediateContainerDeleteFlag){
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample?immediateContainerDeleteFlag=${immediateContainerDeleteFlag}`,
        method: 'DELETE',
        data
    })
}

export async function apiDeleteContainer(data, recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container`,
        method: 'DELETE',
        data
    })
}

export async function apiDisAssociateContainer(data, recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/disassociate?recordId=${recordId}`,
        method: 'PUT',
        data
    })
}

export async function apiDeActivateContainer(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/deactivate?status=false`,
        method: 'PUT',
        data
    })
}

export async function apiDeActivateDisAssociateContainer(data,recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/disassociate/deactivate?recordId=${recordId}`,
        method: 'PUT',
        data
    })
}

export async function apiDeActivateDisAssociateAllContainer(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/disassociate/deactivate/all`,
        method: 'PUT',
        data
    })
}


export async function apiAssociateRecordGeneralInfoToContainer(data,recordId,generalInfoId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/associate/generalInfo/${generalInfoId}?recordId=${recordId}`,
        method: 'POST',
        data
    })
}

export async function apiAssociateRecordGeneralInfoToSample(data,generalInfoId,containerBarcode){
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample/associate/generalInfo/${generalInfoId}/${containerBarcode}`,
        method: 'POST',
        data
    })
}

export async function apiAssociateRecordContainerToSample(data,barcode){
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample/associate/container/${barcode}`,
        method: 'POST',
        data
    })
}

//sub-sample
export async function apiPostSubSample(data,recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample/subsample?recordId=${recordId}`,
        method: 'POST',
        data
    })
}

// Group Composite

export async function apiPostGroupSample(data,recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Sample/groupcomposite?recordId=${recordId}`,
        method: 'POST',
        data
    })
}

export async function apiGetRecordLogs(data) {
    return ApiService.fetchData({
        url: `/api/v1/admin/Logs/record?recordId=${data}`,
        method: 'get',
        data
    })
}

export async function apiSetContainerNumber(recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/setcontainernumber?recordId=${recordId}`,
        method: 'PUT',
    })
}

export async function apiSetContainerNumberPerCore(recordId){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/setcontainernumberpercore?recordId=${recordId}`,
        method: 'PUT',
    })
}

export async function apiGetRecordItemStatus(data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Record/master/item-status`,
        method: 'get',
        data
    })
}

export async function apiGetParentSampleTree(data) {
    return ApiService.fetchData({
        url: `/api/v1/Location/Location/parenttree-from-subsample?barcodes=${data}`,
        method: 'get',
        data
    })
}

export async function apiGetSubSampleTree(data) {
    return ApiService.fetchData({
        
        url: `/api/v1/Location/Location/sampletree-from-parent?barcodes=${data}`,
        method: 'get',
        data
    })
}

export async function apiGetSampleTree(data) {
    return ApiService.fetchData({
        url: `/api/v1/Location/Location/sampletree?barcodes=${data}`,
        method: 'get',
        data
    })
}

export async function apiGetSamplesForDisassociate (data,itemType) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/item?itemtypeid=${itemType}`,
        method: 'POST',
        data
    })
}

export async function apiDisassociateContainerAll(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/disassociate/all`,
        method: 'PUT',
        data
    })
}

export async function apiValidateContainerDelete(data){
    return ApiService.fetchData({
        url: `/api/v1/Orders/OrderRecord/validate/container`,
        method: 'POST',
        data
    })
}

export async function apiValidateRecordDelete (recordId) {
    return ApiService.fetchData({
        url: `/api/v1/Orders/OrderRecord/validate/record/${recordId}`,
        method: 'GET'
    })
}

export async function apiGetChooseExistingCount(data){
    return ApiService.fetchData({
        url: `/api/v1/Record/Record/choose-existing/count`,
        method: 'POST',
        data
    })
}