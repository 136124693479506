import React, { useEffect, useState, useCallback } from "react";

import Theme from "components/template/Theme";
import Layout from "components/layout";
import "./locales";
import { useSelector } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import { Spinner } from "components/ui";
import useAuth from "./utils/hooks/useAuth";
import AccessDenied from "views/Pages/AccessDenied";
import { msalConfig, loginRequest } from "./AuthConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal, useMsalAuthentication
} from "@azure/msal-react";


const AppAzure = (props) => {
  const { signInCustomAuth } = useAuth();
  const [auth, setAuth] = useState(false);
  const authuser = useSelector((state) => state.auth.user);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  const [isTokenResponseSet, setIsTokenResponseSet] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      let tokenResponse = await instance.handleRedirectPromise();
  
      let accountObj;
      if (tokenResponse) {
        accountObj = tokenResponse.account;
      } else {
        accountObj = instance.getAllAccounts()[0];
      }
  
      if (accountObj && tokenResponse) {

        tokenResponse = await instance.acquireTokenSilent({
          account: instance.getAllAccounts()[0],
          ...loginRequest
        })

        const result = await signInCustomAuth(tokenResponse.accessToken);

        if (result.status === "failed") {
          setAuth(false);
        } else {
          setAuth(true);
        }
        setIsTokenResponseSet(true);
      } else if (accountObj) {

        try {
          tokenResponse = await instance.acquireTokenSilent({
            account: instance.getAllAccounts()[0],
            ...loginRequest
          })

          const result = await signInCustomAuth(tokenResponse.accessToken);

          if (result.status === "failed") {
            setAuth(false);
          } else {
            setAuth(true);
          }
          setIsTokenResponseSet(true);
        } catch(err) {
          await instance.acquireTokenRedirect(loginRequest);
        }
      } else {
        
        await instance.loginRedirect(loginRequest)
      }
    } catch (error) {
      console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
    }
  }, [instance]);
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <AuthenticatedTemplate>
        {auth ? (
          authuser.active ? (
            <Theme>
              <Layout />
            </Theme>
          ) : (
            <Spinner size={40} />
          )
        ) : (
          <Spinner size={40} />
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
      <AccessDenied />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default AppAzure;