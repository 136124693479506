import ApiService from "./ApiService";

export async function apiPostOrder(data, selectedExternalUser) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order${
      selectedExternalUser && selectedExternalUser?.id
        ? "?userId=" + selectedExternalUser?.id
        : ""
    }`,
    method: "post",
    data,
  });
}

export async function apiPutOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order`,
    method: "put",
    data,
  });
}

export async function apiApproveOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/status/approve?orderId=${data.id}`,
    method: "put",
    data,
  });
}
export async function apiCancelOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/status/cancel?orderId=${data.id}`,
    method: "put",
    data,
  });
}
export async function apiConfirmOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/status/confirm?orderId=${data.id}`,
    method: "put",
    data,
  });
}
export async function apiUpdateStatusOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/status/to-update?orderId=${data.orderId}&statusId=${data.statusId}`,
    method: "put",
    data,
  });
}

export async function apiSubmitOrder({ orderId }) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/status/submit?orderId=${orderId}`,
    method: "put",
  });
}

export async function apiHoldOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/toggle/hold?orderId=${data.orderId}&hold=${data.hold}`,
    method: "put",
    data,
  });
}

export async function apiGetOrderById(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/${data.id}`,
    method: "get",
    data,
  });
}

export async function apiPostShippingOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/Orders/OrderShipping`,
    method: "post",
    data,
  });
}

export async function apiPutShippingOrder(data) {
  return ApiService.fetchData({
    url: `/api/v1/Orders/OrderShipping`,
    method: "put",
    data,
  });
}

export async function apiPostOrderDisplayRoom(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderDisplay`,
    method: "post",
    data,
  });
}

export async function apiPutOrderDisplayRoom(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderDisplay`,
    method: "put",
    data,
  });
}

export async function apiGetOrderDisplayByOrderId(data) {
  return ApiService.fetchData({
    url: `/api/v1/Orders/OrderDisplay/filter/by-order-id?orderId=${data.id}`,
    method: "get",
    data,
  });
}

export async function apiGetOrderShipmentByOrderId(data) {
  return ApiService.fetchData({
    url: `/api/v1/Orders/OrderShipping/filter/by-order?orderId=${data.id}`,
    method: "get",
    data,
  });
}

export async function apiGetSitesByOrderId(data) {
  return ApiService.fetchData({
    url: `/api/v1/Orders/OrderRecord/filter/sites?orderId=${data.id}`,
    method: "get",
    data,
  });
}

export async function apiPostUserAddress(data) {
  return ApiService.fetchData({
    url: `/api/v1/access/UserAddress`,
    method: "post",
    data,
  });
}

export async function apiDeleteAssociatedRecordsFromOrder(data, order) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/remove?orderId=${order?.id}`,
    method: "delete",
    data,
  });
}

export async function apiUpdateRecordsFromOrder(data, order) {
  return ApiService.fetchData({
    url: `/api/v1/orders/Order/append/records?orderId=${order?.id}`,
    method: "post",
    data,
  });
}

export async function apiGetRoomAvailabilityByFacility(data) {
  const url = data?.orderDisplayId
    ? `?facilityId=${data?.facilityId}&orderDisplayId=${data?.orderDisplayId}`
    : `?facilityId=${data?.facilityId}`;
  return ApiService.fetchData({
    url: `/api/v1/Orders/OrderDisplay/room-availability${url}`,
    method: "get",
    data,
  });
}

export async function apiDeleteOrderDisplay(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderDisplay`,
    method: "delete",
    data,
  });
}

export async function apiGetOrderNotesByOrderId(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderNote/note?orderId=${data.id}`,
    method: "GET",
    data,
  });
}

export async function apiAddOrderNotesByOrderId(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderNote`,
    method: "POST",
    data,
  });
}

export async function apiUpdateOrderNotesByOrderId(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderNote`,
    method: "PUT",
    data,
  });
}

export async function apiDeleteOrderNotesById(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderNote/${data.id}`,
    method: "DELETE",
    data,
  });
}

export async function apiGetUser(data) {
  return ApiService.fetchData({
    url: `/api/v1/access/User/filter?active=false&isInternal=true`,
    method: "GET",
    data,
  });
}

export async function apiGetOrderLogs(data) {
  return ApiService.fetchData({
    url: `/api/v1/admin/Logs/order?OrderId=${data}`,
    method: "get",
    data,
  });
}

//Attachment
export async function apiDeleteOrderAttachment(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderAttachment/${data.id}`,
    method: "DELETE",
  });
}
export async function apiGetOrderAttachmentsById(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderAttachment/attachments?orderId=${data.id}`,
    method: "GET",
    data,
  });
}
export async function apiUpdateOrderAttachment(data) {
  return ApiService.fetchData({
    url: `/api/v1/orders/OrderAttachment`,
    method: "PUT",
    data,
  });
}
