import React from 'react'
import { Container, DoubleSidedImage } from 'components/shared'


const AccessDenied = () => {
	return (
		<Container className="h-full">
			<div className="h-full flex flex-col items-center justify-center">
				<div className="mt-6 text-center">
					<h3 className="mb-2">Access Denied!</h3>
					<p className="text-base">User not in active state.Please contact the administrator.</p>
				</div>
			</div>
		</Container>
	)
}

export default AccessDenied