import labels from './labels';

let labelManager = {};

if (process.env.REACT_APP_DEFAULT_LABEL === 'adnoc') {
  labelManager = labels.adnoc;
} else if (process.env.REACT_APP_DEFAULT_LABEL === 'stratum') {
  labelManager = labels.stratum;
}

export default labelManager;