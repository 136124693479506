import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetStorageReceiptStatus, apiGetOrderStatus, apiGetAppUserPermissionByFacility} from 'services/ListService'
import { apiGetOrderLogs } from 'services/OrderService'
import { apiGetRecordItemStatus, apiGetRecordLogs } from 'services/RecordServices'
import { apiGetSiteLogs } from 'services/SiteService'
import { apiGetReceiptLogs } from 'services/StorageReceiptServices'

export const getReceiptStatus = createAsyncThunk('masterStore/data/getFacility', async (data) => {
    const response = await apiGetStorageReceiptStatus(data)
    return response.data
})

export const getOrderStatus = createAsyncThunk('masterStore/data/getOrderStatus', async (data) => {
    const response = await apiGetOrderStatus(data)
    return response.data
})

export const getaAppUserPermissionByFacility = createAsyncThunk('masterStore/data/getaAppUserPermissionByFacility', async (data) => {
    const response = await apiGetAppUserPermissionByFacility(data)
    return response.data
})

export const getSiteLogs = createAsyncThunk('masterStore/data/getSiteLogs', async (data) => {
    const response = await apiGetSiteLogs(data)
    return response.data.response
})

export const getReceiptLogs = createAsyncThunk('masterStore/data/getReceiptLogs', async (data) => {
    const response = await apiGetReceiptLogs(data)
    return response.data.response
})

export const getRecordLogs = createAsyncThunk('masterStore/data/getRecordLogs', async (data) => {
    const response = await apiGetRecordLogs(data)
    return response.data.response
})

export const getOrderLogs = createAsyncThunk('masterStore/data/getOrderLogs', async (data) => {
    const response = await apiGetOrderLogs(data)
    return response.data.response
})

export const getItemStatus = createAsyncThunk('masterStore/data/getItemStatus', async (data) => {
    const response = await apiGetRecordItemStatus(data)
    return response.data
})

const dataSlice = createSlice({
    name: 'masterStore/data',
    initialState: {
        loading: false,
        receiptStatus: [],
        orderStatus:[],
        recordItemStatus:[],
        appUserPermissionsByFacility: [],
        showActiveRecords: false,
        dataLogs: [],
    },
    reducers: {

        setReceiptStatus: (state, action) => {
            state.receiptStatus = action.payload
        },
        setShowActiveRecords: (state, action) => {
            state.showActiveRecords = action.payload
        },
        setDataLogs: (state, action) => {
            state.dataLogs = action.payload
        },
        setRecordItemStatus:(state,action)=>{
            state.recordItemStatus=action.payload
        }
    },
    extraReducers: {

        [getReceiptStatus.fulfilled]: (state, action) => {
            state.loading = false

            const RECEIPT_STATUS = action?.payload?.reduce((acc, curr) => {
                // const key = curr?.receiptStatusName?.toUpperCase().replace(' ', '');
                const key = curr.receiptStatusName.replace(/[\s-]/g, "_").toUpperCase();
                acc[key] = { statusName: curr.receiptStatusName, statusId: curr.id };
                return acc;
              }, {});
            state.receiptStatus = RECEIPT_STATUS
        },

        [getReceiptStatus.pending]: (state) => {
            state.loading = true
        }, 

        [getOrderStatus.fulfilled]: (state, action) => {
            state.loading = false

            const ORDER_STATUS = action?.payload?.reduce((acc, curr) => {
                const key = curr.orderStatusName.replace(/[\s-]/g, "_").toUpperCase();
                acc[key] = { statusName: curr.orderStatusName, statusId: curr.id, category: curr.category };
                return acc;
              }, {});
            state.orderStatus = ORDER_STATUS
        },

        [getOrderStatus.pending]: (state) => {
            state.loading = true
        }, 
        [getItemStatus.fulfilled]: (state, action) => {
            state.loading = false

            const ITEM_STATUS = action?.payload?.reduce((acc, curr) => {
                const key = curr.statusName.replace(/[\s-]/g, "_").toUpperCase();
                acc[key] = { statusName: curr.statusName, id: curr.id};
                return acc;
              }, {});
            state.recordItemStatus = ITEM_STATUS
        },

        [getItemStatus.pending]: (state) => {
            state.loading = true
        }, 

        [getaAppUserPermissionByFacility.fulfilled]: (state, action) => {
            state.loading = false
            state.appUserPermissionsByFacility = action?.payload;
        },

        [getaAppUserPermissionByFacility.pending]: (state) => {
            state.loading = true
        },
        [getSiteLogs.pending]: (state) => {
            state.loading = true
        },
        [getSiteLogs.fulfilled]: (state, action) => {
            state.loading = true
            state.dataLogs = action.payload
        },
        [getReceiptLogs.pending]: (state) => {
            state.loading = true
        },
        [getReceiptLogs.fulfilled]: (state, action) => {
            state.loading = true
            state.dataLogs = action.payload
        },
        [getRecordLogs.pending]: (state) => {
            state.loading = true
        },
        [getRecordLogs.fulfilled]: (state, action) => {
            state.loading = true
            state.dataLogs = action.payload
        },
        [getOrderLogs.pending]: (state) => {
            state.loading = true
        },
        [getOrderLogs.fulfilled]: (state, action) => {
            state.loading = true
            state.dataLogs = action.payload
        }
    }
})

export const { setReceiptStatus, setDataLogs, setShowActiveRecords } = dataSlice.actions

export default dataSlice.reducer
