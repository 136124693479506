import React, { useEffect, useState, useCallback } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import history from "./history";
import AppAuth from "./AppAuth";
import AppAzure from "./AppAzure";
import { Auth0Provider } from "@auth0/auth0-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from './AuthConfig';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  const [config, setConfig] = useState({});
  const isAuthenticated = useIsAuthenticated();
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const authType = process.env.REACT_APP_AUTH_TYPE;

  const auth0Authority = process.env.REACT_APP_AUTH0_AUTHORITY;
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const auth0RedirectURL = process.env.REACT_APP_AUTH0_REDIRECT_URL;
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const azureADAuthority = process.env.REACT_APP_AZUREAD_AUTHORITY;
  const azureADClientId = process.env.REACT_APP_AZUREAD_CLIENTID;
  const azureADRedirectURL = process.env.REACT_APP_AZUREAD_REDIRECT_URL;

  useEffect(() => {

      if (authType === "AZUREAD") {
        setConfig({
          auth: {
            authority: azureADAuthority,
            clientId: azureADClientId,
            redirectUri: azureADRedirectURL,
          },
          cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true,
          },
          authMode: authType,
        });
 
      } else {
        setConfig({
          auth: {
            domain: auth0Domain,
            audience: auth0Audience,
            clientId: auth0ClientId,
            redirectUri: auth0RedirectURL,
          },
          authMode: authType,
        });
      }
    }, []);


  return config && config?.authMode === "AZUREAD" ? (
    <MsalProvider instance={msalInstance}>

    {<BrowserRouter history={history}>

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppAzure />
        </PersistGate>
      </Provider>
  </BrowserRouter>}
  </MsalProvider>

  ) : config?.authMode === "AUTH0" ? (
    <BrowserRouter history={history}>
      <Auth0Provider
        domain={config?.auth?.domain}
        clientId={config?.auth?.clientId}
        redirectUri={window.location.origin}
        audience={config?.auth?.audience}
      >

        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppAuth config={config} />
          </PersistGate>
        </Provider>
      </Auth0Provider>
    </BrowserRouter>
  ) : (
    <div className="container text-center align-middle">
      Loading...
    </div>
  );
};
export default App;
