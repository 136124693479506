import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    currentRoute: {},
    breadcrumbCurrentTitle: null,
    receiptStatus: []
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setCurrentRoute:(state, action)=>{
          state.currentRoute = action.payload
        },
          setBreadcrumbCurrentTitle: (state, action) => {
            state.breadcrumbCurrentTitle = action.payload
        },
          setReceiptStatus: (state, action) => {
            state.receiptStatus = action.payload
        }
	},
})

export const { setCurrentRouteKey, setCurrentRoute } = commonSlice.actions

export default commonSlice.reducer