export const permissions = {
    contactAdd: "Contact - Add",
    contactEdit: "Contact - Edit",
    contactDelete: "Contact - Delete",
    contactView: "Contact - View",
    siteView: "Site - View",
    siteViewAll: "Site - View All",
    siteAdd: "Site - Add",
    siteEdit: "Site - Edit",
    siteAdmin: "Site - Admin",
    siteDelete: "Site - Delete",
    siteViewTightHole: "Site - View TightHole",
    siteApprove: "Site Approver",
    companyView: "Company - View",
    companyAdd: "Company - Add",
    companyEdit: "Company - Edit",
    companyDelete: "Company - Delete",
    containerTypesView: "ContainerTypes - View",
    containerTypesAdmin: "ContainerTypes - Admin",
    globalAdmin: "Global - Admin",
    countryAdmin: "Country - Admin",
    companyAdmin: "Company - Admin",
    userAdmin: "User - Admin",
    fieldAdmin: "Field - Admin",
    formationAdmin: "Formation - Admin",
    fieldSectorAdmin: "Field Sector - Admin",
    contactAdmin: "Contact - Admin",
    contactGroupAdmin: "Contact Group - Admin",
    contactGroupView: "Contact Group - View",
    storageReceiptAdmin: "StorageReceipts - Admin",
    storageReceiptUser: "StorageReceipts - User",
    storageReceiptViewAll: "StorageReceipts - View All",
    storageReceiptView: "StorageReceipts - View",
    printerAdmin: "Printer - Admin",
    designationCodeAdmin: "DesignationCode - Admin",
    prepCodeAdmin: "PrepCode - Admin",
    recordUser: "Record - User",
    recordView: "Record - View",
    recordEditSiteCompany: "Record - Edit Site",
    recordViewTightHole: "Record - View Tighthole",
    recordViewAll: "Record - View All",
    recordEditFacility: "Record - Edit Facility",
    recordDeactivate: "Record - Deactivate",
    sampleAdmin: "Sample - Admin",
    sampleMove: "Sample - Move",
    containerAdmin: "Container - Admin",    
    containerUser: "Container - User",
    containerViewAll:"Container - View All",
    projectViewAll: "Project - View All",
    projectView: "Project - View",
    projectTightHole: "Project - View Tighthole",
    projectUser: "Project - User",
    orderAdmin: "Order - Admin",
    orderManage: "Order - Manage",
    orderViewAll: "Order - View All",
    orderView: "Order - View",
    orderUser: "Order - User",
    orderCreateOnBehalf: "Order - Create - OnBehalf",
    itemMove:"Item - Move",
    itemMoveFixed: "Item - Move Fixed",
    itemViewLocation: "Item - View Location",
    printBarcodes:"Print Barcodes",
    basinAdmin: "Basin - Admin",
    catalogServiceItemAdmin:"CatalogueServiceItem - Admin",
    displayRoomAdmin: "DisplayRoom - Admin",
    facilityAdmin: "Facility - Admin",    
    licenseAdmin: "License - Admin",
    orderTypeAdmin: "OrderType - Admin",
    recordTemplateAdmin: "Record Template - Admin",
    sampleTypeAdmin:"SampleTypes - Admin",
    storageTypeAdmin: "Storage Type - Admin",
    sampleConditionAdmin: "Sample Condition - Admin",
    resourcePermissionAdmin: "Resource - Permission - Admin",
    shippingCompanyAdmin: "Shipping Company - Admin",
    uomAdmin: "UOM - Admin",
    invoiceAdmin:"Invoice - Admin",
    invoiceUser:"Invoice - User",
    receiptViewAll:"Receipt - View All",
    receiptView : "Receipt - View"
}

export const PERMISSION_NAME = {
    BASIN_ADMIN: "BASIN-ADMIN",
    CATALOG_ADMIN: "CATALOG-ADMIN",
    COMPANY_ADMIN: "COMPANY-ADMIN",
    COMPANY_DELETE: "COMPANY-DELETE",
    CONTACT_ADMIN: "CONTACT-ADMIN",
    CONTAINER_ADMIN: "CONTAINER-ADMIN",
    CONTAINER_TYPES_ADMIN: "CONTAINERTYPES-ADMIN",
    CONTAINER_TYPES_VIEW: "CONTAINERTYPES-VIEW",
    CONTAINER_USER: "CONTAINER-USER",
    CONTAINER_VIEW_ALL: "CONTAINER-VIEWALL",
    COUNTRY_ADMIN: "COUNTRY-ADMIN",
    DISPLAYROOM_ADMIN: "DISPLAYROOM-ADMIN",
    DESIGNATION_CODE_ADMIN: "DESIGNATIONCODE-ADMIN",
    FACILITY_ADMIN: "FACILITY-ADMIN",
    FIELD_ADMIN: "FIELD-ADMIN",
    FIELD_SECTOR_ADMIN: "FIELDSECTOR-ADMIN",
    FORMATION_ADMIN: "FORMATION-ADMIN",
    GLOBAL_ADMIN: "GLOBAL-ADMIN",
    LICENSE_ADMIN: "LICENSE-ADMIN",
    MOVE_ITEM: "MOVEITEM",
    ORDER_TYPE_ADMIN: "ORDERTYPE-ADMIN",
    ORDER_CREATE_ONBEHALF: "ORDER-CREATE-ONBEHALF",
    ORDER_USER: "ORDER-USER",
    ORDER_ADMIN: "ORDER-ADMIN",
    PREP_CODE_ADMIN: "PREPCODE-ADMIN",
    PRINT_BARCODES: "PRINTBARCODES",
    PRINTER_ADMIN: "PRINTER-ADMIN",
    PROJECT_USER: "PROJECT-USER",
    PROJECT_VIEW: "PROJECT-VIEW",
    PROJECT_VIEW_ALL: "PROJECT-VIEWALL",
    PROJECT_VIEW_TIGHTHOLE: "PROJECT-VIEWTIGHTHOLE",
    RECORD_EDIT_FACILITY: "RECORD-EDITFACILITY",
    RECORD_EDIT_SITE: "RECORD-EDITSITE",
    RECORD_LOCATION_VIEW: "RECORD-LOCATION-VIEW",
    RECORD_TEMPLATE_ADMIN: "RECORDTEMPLATE-ADMIN",
    RECORD_USER: "RECORD-USER",
    RECORD_DEACTIVATE: "RECORD-DEACTIVATE",
    RECORD_VIEW: "RECORD-VIEW",
    RECORD_VIEW_ALL: "RECORD-VIEWALL",
    RECORD_VIEW_TIGHTHOLE: "RECORD-VIEWTIGHTHOLE",
    RESOURCE_PERMISSION_ADMIN: "RESOURCE-PERMISSION-ADMIN",
    SAMPLE_ADMIN: "SAMPLE-ADMIN",
    SAMPLE_CONDITION_ADMIN: "SAMPLECONDITION-ADMIN",
    SAMPLE_MOVE: "SAMPLE-MOVE",
    SAMPLE_TYPES_ADMIN: "SAMPLETYPES-ADMIN",
    SAMPLE_TYPES_VIEW: "SAMPLETYPES-VIEW",
    SITE_ADD: "SITE-ADD",
    SITE_ADMIN: "SITE-ADMIN",
    SITE_DELETE: "SITE-DELETE",
    SITE_EDIT: "SITE-EDIT",
    SITE_VIEW: "SITE-VIEW",
    SITE_VIEW_ALL: "SITE-VIEWALL",
    SITE_VIEW_TIGHTHOLE: "SITE-VIEWTIGHTHOLE",
    STORAGE_RECEIPTS_ADMIN: "STORAGERECEIPTS-ADMIN",
    STORAGE_RECEIPTS_USER: "STORAGERECEIPTS-USER",
    STORAGE_RECEIPTS_VIEW: "STORAGERECEIPTS-VIEW",
    STORAGE_RECEIPTS_VIEW_ALL: "STORAGERECEIPTS-VIEWALL",
    STORAGE_TYPE_ADMIN: "STORAGETYPE-ADMIN",
    UOM_ADMIN: "UOM-ADMIN",
    USER_ADMIN: "USER-ADMIN",
    ITEM_MOVE: "ITEM-MOVE",
    ITEM_MOVE_FIXED:"ITEM-MOVEFIXED",
    ITEM_VIEW_LOCATION:"ITEM-VIEWLOCATION",
    INVOICE_ADMIN: "INVOICE-ADMIN",
    INVOICE_USER: "INVOICE-USER"
}