import { createSlice, current } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'masterStore/state',
    initialState: {
        addNewOrExistingSiteDialogOpen: false,
    },
    reducers: {
        toggleaddNewOrExistingSiteDialogOpen: (state, action) => {
            state.addNewOrExistingSiteDialogOpen = action.payload
        },

    },
})

export const { 
    toggleaddNewOrExistingSiteDialogOpen,
} = stateSlice.actions

export default stateSlice.reducer
