import React, { useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from "components/ui";
import useAuth from './utils/hooks/useAuth';
import AppAuthAccess from './AppAuthAccess';

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'


import './locales'

const AppAuth=(props) =>{

  const { isLoading } = useAuth0();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  if (isLoading) return <div className="container text-center align-middle">Loading...</div>
  if (!isAuthenticated) {
    loginWithRedirect();
  }
	
  return (
    <div>
    { isAuthenticated ?
			
      <AppAuthAccess config={props.config}/>
        :<Spinner size={40} />
        }
  </div>
  );
  
}
export default AppAuth;

