import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, setLoading } from 'store/auth/userSlice'
import { getReceiptStatus, getOrderStatus, getaAppUserPermissionByFacility, getItemStatus } from 'store/master/dataSlice'
import { apiSignIn, apiSignOut,apiGetUsersDetails } from 'services/AuthService'
import { onApiLoaded, onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { data } from 'autoprefixer'
import { isNull } from 'lodash'
import { permissions } from 'constants/permission.constant'
import { useAuth0 } from '@auth0/auth0-react';
import { Notification, toast } from "components/ui";
import { errorHandlerToast } from 'utils/utilService'

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { logout  } = useAuth0();

	const { token, signedIn } = useSelector((state) => state.auth.session)

	const fetchData = () => {
		dispatch(getaAppUserPermissionByFacility())
	}

	const signIn = async ({ userName, password }) => {
		try {
			const resp = await apiSignIn({ userName, password })
			if (resp.data) {
				const { token } = resp.data
				fetchData()
				dispatch(onSignInSuccess(token))
				if (resp.data.user) {
					// dispatch(setUser(resp.data.user || {
					// 	avatar: '',
					// 	userName: 'Anonymous',
					// 	authority: ['USER'],
					// 	email: ''
					// }))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}


	const isApiCallSuccess = (result) => {			
		dispatch(onApiLoaded(result))
	}	

	const signInCustomAuth = async (aToken) => {
		let isSuccess = false
		try {

			dispatch(onSignInSuccess(aToken))	
			dispatch(setLoading(true))
			const timer =  setTimeout(async () => {
				try {
				const resp = await apiGetUsersDetails()				
				if (resp.data) {
					isSuccess=true
					if(resp.data.permissions){
						fetchData()
						let hasPermission = resp.data.permissions.filter(
						(x) =>
							x?.permissionName.includes(permissions.storageReceiptAdmin) ||
							x?.permissionName.includes(permissions.storageReceiptUser) ||
							x?.permissionName.includes(permissions.storageReceiptViewAll) || 
							x?.permissionName.includes(permissions.storageReceiptView) 
						);

						let hasInternalUserOrderPermission = resp.data.permissions.filter(
						(x) =>
							x?.permissionName.includes(permissions.orderAdmin) ||
							x?.permissionName.includes(permissions.orderUser) ||
							x?.permissionName.includes(permissions.recordUser) ||
							x?.permissionName.includes(permissions.recordView) ||
							x?.permissionName.includes(permissions.recordViewAll) || 
							x?.permissionName.includes(permissions.recordViewTightHole)
						);

						let hasExternalUserOrderPermission = resp.data.permissions.filter(
						(x) => x?.permissionName.includes(permissions.orderManage) ||
						x?.permissionName.includes(permissions.recordUser) ||
						x?.permissionName.includes(permissions.recordView) ||
						x?.permissionName.includes(permissions.recordViewAll) || 
						x?.permissionName.includes(permissions.recordViewTightHole)
						);

						let hasRecordPermission = resp.data.permissions.filter(
						(x) =>
							x?.permissionName.includes(permissions.recordUser) ||
							x?.permissionName.includes(permissions.recordView) ||
							x?.permissionName.includes(permissions.recordViewAll) ||
							x?.permissionName.includes(permissions.recordEditFacility) ||
							x?.permissionName.includes(permissions.recordEditSiteCompany)||
							x?.permissionName.includes(permissions.recordViewTightHole)
						);
						if(hasPermission.length > 0) {							
							dispatch(getReceiptStatus())
						} 
						if(hasRecordPermission.length >0){
							dispatch(getItemStatus());
						}
						if((resp.data.company.internal && hasInternalUserOrderPermission.length > 0) || 
						(!resp.data.company.internal && hasExternalUserOrderPermission.length > 0) ||
						(resp.data.company.internal && hasPermission.length > 0) || 
						(!resp.data.company.internal && hasPermission.length > 0)
						) {
							dispatch(getOrderStatus())
						}
					}
					dispatch(setUser({
						avatar: resp.data.avatar ?resp.data.avatar : '',
						firstName: resp.data.firstName,
						lastName:resp.data.lastName,
						userName:resp.data.displayName,
						company: resp.data.company,
						authority: resp.data.permissions ? resp.data.permissions.map(s=>s?s.permissionName:'') : ['empty'],
						email: resp.data.email,
						role:resp.data.roles != null ? resp.data.roles.map(s=>s?s.roleName:'') : [],
						locationId:resp.data.locationId,
						userId:resp.data.userId,
						active: resp.data.active,
						facilityId: resp.data.facilityId
					}))	
				dispatch(setLoading(false))

			}
			if(resp.status === 400){
				isSuccess = false		
			}
			}catch (errors) {
			isSuccess = false;	
			dispatch(setLoading(false))
			}finally{
				isApiCallSuccess(isSuccess)
				dispatch(setLoading(false))
			}
			}, 500);
			return () => {
				clearTimeout(timer);				
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
				return {
					status: isSuccess,
					message:'',					
				}	
			}
						
			
			
		} catch (errors) {
			return {
				status: isSuccess,
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut =  () => {
		try {
			logout()
			// handleSignOut()
		} catch (errors) {
			errorHandlerToast(errors)
		}
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signOut,
		signInCustomAuth,
		isApiCallSuccess		
	}
}

export default useAuth