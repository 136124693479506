import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    firstName: '',
    lastName:'',
    email: '',
    authority: [],
    role:[],
    locationId:'',
    userId:'',
    userName:'',
    company: {},
    loading: false,
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        setLoading:(state, action)=>{
            state.loading = action.payload
        },
        userLoggedOut: () => initialState,
	},
})

export const { setUser, setLoading } = userSlice.actions

export default userSlice.reducer