import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { CollapseContextProvider } from './context/collapseContext'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import MenuContext from './context/menuContext'
import { HiChevronDown } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSideMenu } from 'store/theme/themeSlice'


const MenuCollapse = props => {

	const {
		children,
		className,
		eventKey,
		expanded,
		label,
		onToggle,
		nav
	} = props

	// const [selectedSideMenu, setSelectedSideMenu] = useState(null)
	const { authority } = useSelector((state) => state.auth.user);
	// console.log("nav", nav);
	const selectedSideMenu = useSelector((state) => state?.theme?.selectedSideMenu)
	const dispatch = useDispatch()

	const { menuItemHeight, variant, sideCollapsed, defaultExpandedKeys } = useContext(MenuContext)

	useEffect(() => {
		const isExpanded = selectedSideMenu?.title === nav?.title
		if (defaultExpandedKeys.includes(eventKey)) {
			dispatch(setSelectedSideMenu(nav))
		}
		if (expanded !== isExpanded) {
			dispatch(setSelectedSideMenu(nav))
		}
	}, [expanded, eventKey, defaultExpandedKeys])

	const toggleCollapse = e => {
		const isExpanded = selectedSideMenu?.title === nav?.title
		if (typeof onToggle === 'function') {
			onToggle(!isExpanded, e)
		}
		if(isExpanded){
			 dispatch(setSelectedSideMenu(null))
		} else {
			dispatch(setSelectedSideMenu(nav))
		}
	}

	const getChildrenHeight = () => {
		let height = 0
		let count = 0
		if ((selectedSideMenu?.title === nav?.title) && children && children.length) {
			if (authority.length) {
				for (let i = 0; i < children.length; i++) {
					const found = authority.some(r => children[i].props.authority.includes(r))
					if (found) {
						count = count + 1;
					} else if (children[i].props.authority.length === 0) {
						count = count + 1;
					}
				}
				height = count * menuItemHeight
			} else {
				height = children.length * menuItemHeight
			}
		}
		if ((selectedSideMenu?.title === nav?.title) && children && !children.length) {
			height = menuItemHeight
		}
		return height
	}

	const menuCollapseItemClass = classNames(
		'menu-collapse-item',
		`menu-collapse-item-${variant}`,
		className,
	)



	return (
		<div className="menu-collapse" >
			<div className={menuCollapseItemClass} onClick={toggleCollapse}>
				<span className="flex items-center">{label}</span>
				<motion.span
					className="text-lg mt-1"
					initial={{ transform: 'rotate(0deg)' }}
					animate={{
						transform: selectedSideMenu?.title === nav?.title ? 'rotate(-180deg)' : 'rotate(0deg)',
					}}
					transition={{ duration: 0.15 }}
				>
					{sideCollapsed ? null : <HiChevronDown />}
				</motion.span>
			</div>
			<CollapseContextProvider value={selectedSideMenu?.title === nav?.title}>
				<motion.ul
					className="ml-5"
					initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
					animate={{
						opacity: selectedSideMenu?.title === nav?.title ? 1 : 0,
						height: selectedSideMenu?.title === nav?.title ? getChildrenHeight() : 0,
					}}
					transition={{ duration: 0.15 }}
				>
					{children}
				</motion.ul>
			</CollapseContextProvider>
		</div>
	)
}

MenuCollapse.propTypes = {
	expanded: PropTypes.bool,
	onToggle: PropTypes.func,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	children: PropTypes.node,
	className: PropTypes.string,
	nav:PropTypes.object
}

MenuCollapse.defaultProps = {
	expanded: false,
	label: null
}

export default MenuCollapse
