import { toast, Notification } from "components/ui";
import { Button, Tabs } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs'


export function isUndefinedOrNullOrEmpty(value) {
    let isInValid = false;
	if (value === undefined || value === null || value === '') { 
        isInValid = true 
	} 
    return isInValid
}

export function isUndefinedNull(value) {
    let isInValid = false;
	if (value === undefined || value === null) { 
        isInValid = true 
	} 
    return isInValid
}


export function isLatitude(data){
	if(data >=-90 && data<=90){
		return true
	}
	return false
}

export function isLongitude(data){
	if(data >=-180 && data<=180){
		return true
	}
	return false
}

export function replaceEmptyStringWithNull (obj) {
	for (const key in obj) {
	  if (typeof obj[key] === 'string' && obj[key] === "") {
		obj[key] = null;
	  } else if (typeof obj[key] === 'object' && obj[key] !== null) {
		replaceEmptyStringWithNull(obj[key]);
	  }
	}
	return obj;
  };

export function IsInternalUser() {
	const { company }  = useSelector(state => state.auth.user)

    return company?.internal
}

  export function useCheckModulePermission() {

	const permissions = useSelector(state => state.master.data.appUserPermissionsByFacility);
  
	return function(permissionName) {
		if(permissions && permissions.length > 0 && permissionName) {
			const exists = permissions.some(facility => {
				return facility?.permission.some(perm => {
				  return perm.uiPermission === permissionName;
				});
			  });
			  return exists
		}
	}
  }
  

export function useCheckFaclityPermission() {

	const permissions = useSelector(state => state.master.data.appUserPermissionsByFacility);

	return function(roles, facilityId) {
		if (permissions && permissions.length > 0 && facilityId && roles) {
		  const facilityPermissions = permissions.find(p => p.facilityId === facilityId);
		  if (!facilityPermissions) {
			return false;
		  }
		  return roles?.some(role => {
			const permission = facilityPermissions.permission.find(p => p.uiPermission === role);
			return permission !== undefined;
		  }) ?? false;
		}
		return false;
	  }
  }

  export function useCheckMultipleFacilityPermission() {
	const permissions = useSelector(state => state.master.data.appUserPermissionsByFacility);
  
	return function(roles, facilityIds) {
	  const matchingPermissions = [];
  
	  if (permissions && permissions.length > 0 && facilityIds && roles) {
		for (const facilityId of facilityIds) {
		  const facilityPermissions = permissions.find(p => p.facilityId === facilityId);
		  if (facilityPermissions) {
			const matchingRoles = roles.filter(role => {
			  const permission = facilityPermissions.permission.find(p => p.uiPermission === role);
			  return permission !== undefined;
			});
  
			if (matchingRoles.length > 0) {
			  matchingPermissions.push({
				facilityId,
				permissions: matchingRoles
			  });
			}
		  }
		}
	  }
  
	  return matchingPermissions;
	}
  }
  
  
  

export function errorHandlerToast(errors) {
	let errorObj = {
		title: "Error Occurred",
		msg: "Something went wrong. Please check logs for more information."
	}
	if(errors && errors?.response && errors?.response?.data && errors?.response?.data?.Errors) {
		errorObj.title = "Error";
		errorObj.msg = errors?.response?.data?.Errors;
	}
	if (errors && errors?.data && !errors?.data?.status?.isSuccess && errors?.data?.status?.errors === null && errors?.data?.status?.message === null) {
		errorObj.title = "Error";
		errorObj.msg = errors?.data?.response[0].errorMessage;
	}
	if (errors && errors?.data && !errors?.data?.status?.isSuccess && errors?.data?.status?.errors === null && errors?.data?.status?.message) {
		errorObj.title = "Error";
		errorObj.msg = errors?.data?.status.message;
	}

	if(errors && errors?.data && !errors?.data?.status?.isSuccess && errors?.data?.status?.errors) {
		errorObj.title = "Error";
		errorObj.msg = errors?.data?.status.errors[0].errorMessage;
	} 
	const errToast = toast.push(
		<Notification title={errorObj.title} type="warning" duration={3500}>
		  <div>
		  {errorObj.msg}
			</div>
		</Notification>,
		{
		  placement: "top-center",
		}
	  );
    return errToast
}

export function apiResponseErrorHandlerToast(errorArray) {

	const errorsByProperty = {};
	errorArray.forEach(error => {
		const { propertyName, errorMessage } = error;
		if (!errorsByProperty[propertyName]) {
		  errorsByProperty[propertyName] = [];
		}
		errorsByProperty[propertyName].push(errorMessage);
	  });

	const errorList = Object.entries(errorsByProperty).map(([propertyName, messages]) => (
		<div key={propertyName}>
		  <ul>
			{messages.map((message, index) => (
			  <li key={index}>{message}</li>
			))}
		  </ul>
		</div>
	  ));

	const errToast =  toast.push(
		<Notification title={"Error"} type="warning" duration={3500}>
			<div>
			{errorList}
			</div>
		</Notification>,
		{
		  placement: "top-center",
		}
	  );
    return errToast
}

export function multiErrorHandlerToast(errorArray) {

	const errorsByProperty = {};
	errorArray.forEach(errorGroup => {		
	  errorGroup.forEach(error => {
		const { propertyName, errorMessage } = error;
		if (!errorsByProperty[propertyName]) {
		  errorsByProperty[propertyName] = [];
		}
		errorsByProperty[propertyName].push(errorMessage);
	  });
	});

	const errorList = Object.entries(errorsByProperty).map(([propertyName, messages]) => (
		<div key={propertyName}>
		  <ul>
			{messages.map((message, index) => (
			  <li key={index}>{message}</li>
			))}
		  </ul>
		</div>
	  ));

	const errToast =  toast.push(
		<Notification title={"Error"} type="warning" duration={3500}>
			<div>
			{errorList}
			</div>
		</Notification>,
		{
		  placement: "top-center",
		}
	  );
    return errToast
}

export function customErrorHandlerToast(title, description) {
	const errToast =  toast.push(
		<Notification title={title} type="warning" duration={3500}>
		  {description}
		</Notification>,
		{
		  placement: "top-center",
		}
	  );
    return errToast
}


export function successHandlerToast(title, description) {
	const errToast =  toast.push(
		<Notification title={title} type="success" duration={3500}>
		  {description}
		</Notification>,
		{
		  placement: "top-center",
		}
	  );
    return errToast
}

// export function convertToSelectOptions(list, value, label) {
// var options = []
//     if(!isUndefinedOrNullOrEmpty(list) && list.length > 0) {
// 		for (let i = 0; i < list.length; i++) {
// 			if(list[i]){
// 				var row = {
// 					value: list[i][value],
// 					label: list[i][label]
// 				}
// 				options.push(row);
// 			}
// 		}
// 	}
//     return options
// }

export function convertToSelectOptions (list, valueKey, labelKey) {

	return list?.map((item) => ({
	  value: item[valueKey],
	  label: item[labelKey],
	}));
}

export function convertToSelectOptionsOptional(list, valueKey, labelKey, category = null) {
	return list?.map((item) => ({
	  value: item[valueKey],
	  label: item[labelKey],
	  ...(category && { category: item[category] }),
	}));
  }

export function convertToUserAddressSelectOptions (list) {

	return list?.map((item) => ({
	  value: item.addressId,
	  label: item.address.addressName,
	}));
}

export function formatDate(dateObj) {
	return dayjs(dateObj).format('MMM DD, YYYY')
}

export function getDayFromDate(dayIndex) {
	const days = [
		'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
	  ];

	  const dayName = days[dayIndex];
	  return dayName;
}

export function formatDateWithTimeStamp(dateObj) {
	return dayjs(dateObj).format('MMM DD, YYYY hh:mm:ss A')
}

export function convertDateObjToIsoString (date) {

	if(typeof date === 'string') {
		return date
	}
	let utcDate = new Date(Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
		date.getSeconds()
		));
	let isoStringDate = utcDate.toISOString();
	return isoStringDate
 }

 export function checkInvalidData(updates,columns, defaultColumns){
	const errors=[];
	for(let i=0;i<updates.length;i++){
		let x= updates[i];
		Object.keys(x).map((key, index) => {
			index = columns.findIndex(x=>x.data==key)
			let defaultIndex = defaultColumns.findIndex(x=>x.MetaDataName==key)
			if(index != -1){
				const constDetail = columns[index];       
				if(constDetail.type == 'numeric'){
					if(!Number.isInteger(x[key]) && defaultIndex == -1){
						errors.push(key);
					}
				}else{
					if(!x[key] && defaultIndex == -1){
						errors.push(key);
					}
				}
			}
		});
	}
	return errors.filter((item,index) => errors.indexOf(item) === index);
}

export function checkProperties(obj,keyList) {
    for (var key in obj) {
        if (obj[key] !== null && obj[key] != "" && !keyList.includes(key))
            return false;
    }
    return true;
}


export function getMonthFromDate(monthIndex) {
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	  ];

	  const monthName = months[monthIndex];
	  return monthName;
}


export function isObjectEmpty(obj) {
	return Object.keys(obj).length === 0;
}
