import { isObjectEmpty } from "utils/utilService"
import ApiService from "./ApiService"

export async function apiGetAdminDashboardData (data) {
    return ApiService.fetchData({
        url: '/api/v1/access/User/getdashboard',
        method: 'get',
        data
    })
}

export async function apiGetAppUserPermissionByFacility (data) {
    return ApiService.fetchData({
        url: '/api/v1/access/UserPermission/facility/permission',
        method: 'get',
        data
    })
}

export async function apiGetSalesProducts (data) {
    return ApiService.fetchData({
        url: '/sales/products',
        method: 'post',
        data
    })
}

export async function apiDeleteSalesProducts (data) {
    return ApiService.fetchData({
        url: '/sales/products/delete',
        method: 'delete',
        data
    })
}

export async function apiGetSalesProduct (params) {
    return ApiService.fetchData({
        url: '/sales/product',
        method: 'get',
        params
    })
}

export async function apiPutSalesProduct (data) {
    return ApiService.fetchData({
        url: '/sales/products/update',
        method: 'put',
        data
    })
}

export async function apiCreateSalesProduct (data) {
    return ApiService.fetchData({
        url: '/sales/products/create',
        method: 'post',
        data
    })
}

export async function apiGetSalesOrders (params) {
    return ApiService.fetchData({
        url: '/sales/orders',
        method: 'get',
        params
    })
}

export async function apiDeleteSalesOrders (data) {
    return ApiService.fetchData({
        url: '/sales/orders/delete',
        method: 'delete',
        data
    })
}

export async function apiGetSalesOrderDetails (params) {
    return ApiService.fetchData({
        url: '/sales/orders-details',
        method: 'get',
        params
    })
}

export async function apiGetCountries (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Country',
        method: 'get',
        params
    })
}

export async function apiGetCompanies (params) {
    return ApiService.fetchData({
        url: `/api/v1/admin/Company?active=${isObjectEmpty(params) ? 'false' : params}`,
        method: 'get',
        params
    })
}

export async function apiGetExternalCompanies (params) {
    return ApiService.fetchData({
        url: `/api/v1/admin/company/internalexternal?active=${params?.active}&internalCompany=${params?.internalCompany}`,
        method: 'get',
    })
}

export async function apiSyncCompanny (userId, data = {}) {
    return ApiService.fetchData({
        url: `api/v1/access/user/synctoauth0?userId=${userId}`,
        method: 'post',
        data
    })
}

export async function apiGetFieldSector (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/FieldSector',
        method: 'get',
        params
    })
}

export async function apiGetOrderType (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/OrderType',
        method: 'get',
        params
    })
}

export async function apiGetOrderPurpose (params) {
    return ApiService.fetchData({
        url: '/api/v1/orders/Order/master/order-purpose',
        method: 'get',
        params
    })
}

export async function apiGetSubOrderType (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/OrderType',
        method: 'get',
        params
    })
}

export async function apiGetUnitOfMeasure (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/UnitOfMeasure',
        method: 'get',
        params
    })
}

export async function apiGetBasin (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Basin',
        method: 'get',
        params
    })
}

export async function apiGetStorageTypeByActive (params){
    return ApiService.fetchData({
        url: `/api/v1/admin/StorageType?active=${params?.active? true: false}`,
        method: 'get'
    })
}

export async function apiGetStorageType (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/StorageType',
        method: 'get',
        params
    })
}

export async function apiGetSampleTypeList (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/SampleType',
        method: 'get',
        params
    })
}

export async function apiGetActiveSampleTypeList (params) {
    return ApiService.fetchData({
        url: `/api/v1/admin/SampleType/onlyactive`,
        method: 'get'
    })
}


export async function apiGetSampleTypeByCountryId (params) {
    return ApiService.fetchData({
        url: `/api/v1/admin/SampleType/get/?active=true`,
        method: 'get'
    })
}

export async function apiGetSampleCondition (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/SampleCondition',
        method: 'get',
        params
    })
}

export async function apiGetFacility (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility',
        method: 'GET',
        data
    })
}

export async function apiGetDisplayRoomFacility (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility/access/display-room',
        method: 'GET',
        data
    })
}

export async function apiGetAccessFacility (data) {
    return ApiService.fetchData({
        url: '/api/v1/Receipts/Receipt/access/facility',
        method: 'GET',
        data
    })
}

export async function apiGetAccessFacilityOrder (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility/access/order',
        method: 'GET',
        data
    })
}

export async function apiGetFacilityShippingOrder (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility?active=true',
        method: 'GET',
        data
    })
}

export async function apiGetAccessAllFacilityOrder (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility?active=true',
        method: 'GET',
        data
    })
}

export async function apiGetUsersListByCompanyId (userFilter) {
    return ApiService.fetchData({
        url: `/api/v1/access/User/filter?
        active=${userFilter?.isActiveUser}&
        isInternal=${userFilter?.isInternalUser}&
        companyId=${userFilter?.companyId}`,
        method: 'get',
    })
}

export async function apiGetAccessFacilityContainer (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Facility/access/container',
        method: 'GET',
        data
    })
}


export async function apiGetAccessFacilityContainerWithROle (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/Role/permissible/facilities?csvPermission=${data.permissions}`,
        method: 'GET',
        data
    })
}

export async function apiGetAccessRecordFacility (data) {
    return ApiService.fetchData({
        url: '/api/v1/Record/Record/access/facility',
        method: 'GET',
        data
    })
}

export async function apiGetUsers (params) {
    return ApiService.fetchData({
        url: '/api/v1/access/User',
        method: 'get',
        params
    })
}

export async function apiGetActiveInternalUsers (userFilter) {
    return ApiService.fetchData({
        url: `/api/v1/access/User/filter?active=${userFilter?.isActiveUser}&isInternal=${userFilter?.isInternalUser}`,
        method: 'get',
    })
}

export async function apiGetUsersList (userFilter) {
    return ApiService.fetchData({
        url: `/api/v1/access/User/filter?active=${userFilter?.isActiveUser}&isInternal=${userFilter?.isInternalUser}`,
        method: 'get',
    })
}

export async function apiGetShippingCompany (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/ShippingCompany',
        method: 'get',
        params
    })
}

export async function apiGetFacilities () {
    return ApiService.fetchData({
        url: `/api/v1/access/Permission/permissible/facilities`,
        method: 'GET',
    })
}

export async function apiGetContainersByBarcode (data) {
    return ApiService.fetchData({
        url: '/api/v1/Record/Container/filter/barcode',
        method: 'post',
        data
    })
}

export async function apiGetStorageReceiptStatus (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/master/status`,
        method: 'get',
    })
}

export async function apiGetOrderStatus (data) {
    return ApiService.fetchData({
        url: `/api/v1/orders/Order/master/order-status`,
        method: 'get',
    })
}

export async function apiGetDesignationCodes(data){
    return ApiService.fetchData({
        url: `/api/v1/admin/DesignationCode`,
        method: 'GET',
    })
}

export async function apiGetDisplayRoomCompany (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/DisplayRoomCompany',
        method: 'get',
        params
    })
}

export async function apiGetWorkWeek (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/DisplayRoom/master/workweek',
        method: 'get',
        params
    })
}


export async function apiGetDisplayRoom (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/DisplayRoom',
        method: 'get',
        params
    })
}

export async function apiGetDisplayRoomByFacility (data) {
    return ApiService.fetchData({
        url: data?.projectId? `/api/v1/admin/DisplayRoom/filter/by-facility?facilityId=${data?.facilityId}&projectId=${data?.projectId}`: `/api/v1/admin/DisplayRoom/filter/by-facility?facilityId=${data?.facilityId}`,
        method: 'get',
    })
}

export async function apiGetSavedAddress (param) {
    return ApiService.fetchData({
        url: `/api/v1/access/UserAddress/user-address?userId=${param}`,
        method: 'get',
        param
    })
}

export async function apiGetShipTo (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/DisplayRoom',
        method: 'get',
        params
    })
}

export async function apiGetPrinterSize (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/PrinterSize',
        method: 'get',
        params
    })
}

export async function apiGetPrinters (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Printers',
        method: 'get',
        params
    })
}

export async function apiGetPrinterTemplate (params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/PrinterTemplate',
        method: 'get',
        params
    })
}

export async function apiGetAttachmentTypes(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/AttachmentType?active=true',
        method: 'get',
        params
    })
}

export async function apiGetCompanyContract(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/CompanyContract?active=true',
        method: 'get',
        params
    })
}

export async function apiGetCompanyContractByCompanyId(params) {
    return ApiService.fetchData({
        url: `/api/v1/admin/CompanyContract/filter/by-company?companyid=${params.companyId}`,
        method: 'get',        
    })
}
export async function apiGetInvoiceSummaryTypeList(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/InvoiceConfiguration/InvoiceSummaryType?active=true',
        method: 'get',
        params
    })
}
export async function apiGetReportByList(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/InvoiceConfiguration/InvoiceReportBy?active=true',
        method: 'get',
        params
    })
}
export async function apiGetSampleCategoryTypeList(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/SampleTypeCategory?active=true',
        method: 'get',
        params
    })
}
export async function apiGetInvoiceFrequencyList(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/InvoiceConfiguration/InvoiceIntervalType?active=true',
        method: 'get',
        params
    })
}
export async function apiGetInvoiceGroupLevelList(params) {
    return ApiService.fetchData({
        url: '/api/v1/admin/InvoiceConfiguration/InvoiceConfigType?active=true',
        method: 'get',
        params
    })
}

export async function apiGetFieldList(params) {
    return ApiService.fetchData({
        url: 'api/v1/admin/Field?active=false',
        method: 'get',
        params
    })
}
