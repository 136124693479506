import React, { useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from "components/ui";
import useAuth from './utils/hooks/useAuth';
import { useSelector } from "react-redux"; 

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'


import './locales'
import AccessDenied from "views/Pages/AccessDenied";
import NoUser from "views/Pages/NoUser";


const AppAuthAccess = (props) => {
  const { signInCustomAuth, isApiCallSuccess } = useAuth();
  const [auth, setAuth] = useState(false);
  const [status, setStatus] = useState(false)
  const [isAuthenticated] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const [wellList, setWellList] = useState({});
  const [config,setConfig]=useState(props.config);
  const authuser = useSelector((state) => state.auth.user);
	const { isApiLoaded } = useSelector((state) => state.auth.session)



  useEffect(() => {
    fetchData();
  }, [isAuthenticated, isApiLoaded])

  async function fetchData() {
    //const audience = "https://apiinvlocal.stratumreservoir.com";
     const audience = config.auth.audience;
    try {
      console.log("access_token")
      const accessToken = await getAccessTokenSilently({
        audience: audience
      });

      console.log(accessToken)
       await signInCustomAuth(accessToken).then((data)=>{        
          if (!isApiLoaded) {
            setAuth(false);
            setStatus(false)
          } else {
            setAuth(true);
            setStatus(true)
          }        
      });   

    } catch (e) {
      console.log(e.message);
    }

  }
    
  if(authuser?.loading && authuser.active === undefined) {
    return (
      <div>
        <Spinner size={40} />
      </div>
    );
  } else if (!isApiLoaded && !status) {  
    return (
      <div>
        <NoUser />
      </div>
    );    
  } else if (auth) {
    if (authuser.active) {
      return (
        <div>
          <Theme>
            <Layout />
          </Theme>
        </div>
      );
    } else {
      return (
        <div>
          <AccessDenied />
        </div>
      );
    }
  } else {
    return (
      <div>
        <Spinner size={40} />
      </div>
    );
  }  

}
export default AppAuthAccess;

